export default {
  watch: {
    searchFields: {
      deep: true,
      handler($val) {
        this.filterFields = this.moduleFilter(this.searchFields, $val)
      },
    },
  },
}
